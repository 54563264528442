import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Product, Catalog_values, Order, Ordenes, Category, DeliveryInfo, GuestLogin, MinimumPieces, Catalog } from './app.models';
import { environment } from '../environments/environment';
import { OrdersService } from './services/orders.service';
import { User } from './admin/users/user.model';
import { UserLogguedData, UserLogguedBD } from '../app/models/user_loggued.model';
import { CognitoService } from '../app/services/cognito.service';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { Router, NavigationEnd } from '@angular/router';
import { UsersService } from '../app/services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { CatalogsService } from '../app/services/catalogs.service';
import { firstValueFrom } from 'rxjs';


export class Data {
    constructor(public categories: Category[],
        public compareList: Product[],
        public wishList: Product[],
        public cartList,
        public totalPrice: number,
        public totalCartCount: number,
        public shippingPercentage: number,
        public remainingShippingAmount: number,
        public isFreeShipping: boolean,
        public freeShippingAvailable: boolean,
        public order?: Order,
        public userName?: string,
        public userMail?: string,
        public familyName?: string,
        public userLoggedData?: UserLogguedData,
        public userLoggedDataBD?: UserLogguedBD,
        public discountCouponCode?: string,
        public country?: string,
        public deliveryInformation?: DeliveryInfo,
        public minimumPieces?: MinimumPieces
    ) { }

}

@Injectable()
export class AppService {
    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        null, //totalPrice,
        0,//totalCartCount,
        0, // shippingPercentage,
        0, // remainingShippingAmount
        false, // isFreeShipping
        false, // freeShippingAvailable
        null,// order
        "", //userName
        null, //userMail
        null, //familyName
        null, //userLoggedData
        null, //userLoggedDataBD
        null, //discountCouponCode
        null, // country
        null  // minimumPieces
    )


    public url = environment.country === 'MX'?environment.url+ '/assets/data/':environment.usa_url + '/assets/data/';

    constructor(public http: HttpClient, 
        public snackBar: MatSnackBar, 
        public ordersService: OrdersService, 
        public cognitoService?: CognitoService, 
        public router?: Router, 
        public usersService?: UsersService, 
        private spinner?: NgxSpinnerService,
        private cookies?: CookieService, 
        private translate?: TranslateService,
        public catalogsService?: CatalogsService, ) { 
    }

    // public getCategories(): Observable<Category[]> {
    //     return this.http.get<Category[]>(this.url + 'categories.json');
    // }

    public getProducts(type): Observable<Product[]> {
        return this.http.get<Product[]>(this.url + type + '-products.json');
    }

    public getProductById(id): Observable<Product> {
        return this.http.get<Product>(this.url + 'product-' + id + '.json');
    }

    public getBanners(): Observable<any[]> {
        return this.http.get<any[]>(this.url + 'banners.json');
    }

    public addToCompare(product: Product) {
        let message, status;
        if (this.Data.compareList.filter(item => item._id == product._id)[0]) {
            message = 'The product ' + product.name + ' already added to comparison list.';
            status = 'error';
        }
        else {
            this.Data.compareList.push(product);
            message = 'The product ' + product.name + ' has been added to comparison list.';
            status = 'success';
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToWishList(product: Product) {
        let message, status;
        if (this.Data.wishList.filter(item => item._id == product._id)[0]) {
            message = 'The product ' + product.name + ' already added to wish list.';
            status = 'error';
        }
        else {
            this.Data.wishList.push(product);
            message = 'The product ' + product.name + ' has been added to wish list.';
            status = 'success';
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToCart(product: Product) {
        let message, status;

        this.Data.totalPrice = 0;
        this.Data.totalCartCount = 0;
        // this.Data.cartList.filter(item => item._id == product._id)[0]
        if (this.Data.cartList.filter(item => item._id == product._id)[0]) {
            let item = this.Data.cartList.filter(item => item._id == product._id)[0];
            item.cartCount = product.cartCount;
        }
        else {
            this.Data.cartList.push(product);
        }
        // this.Data.cartList.forEach(product => {
        //     this.Data.totalPrice = this.Data.totalPrice + Number(product.price.$numberDecimal);
        //     this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
        // });
        this.getOrder();

        // localStorage.setItem('cart_items', JSON.stringify(this.Data.cartList));
        // localStorage.setItem('totalPrice', this.Data.totalPrice.toString());
        // localStorage.setItem('totalCartCount', this.Data.totalCartCount.toString());

        message = 'El producto ' + product.name + ' ha sido añadido al carrito.';
        status = 'success';

        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public resetProductCartCount(product: Product) {
        product.cartCount = 0;
        let compareProduct = this.Data.compareList.filter(item => item._id == product._id)[0];
        if (compareProduct) {
            compareProduct.cartCount = 0;
        };
        let wishProduct = this.Data.wishList.filter(item => item._id == product._id)[0];
        if (wishProduct) {
            wishProduct.cartCount = 0;
        };
    }

    getOrder() {
        this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
            if (res.order.products) {
                this.Data.totalPrice = res.order.amount;
                this.Data.totalCartCount = res.order.quantity;
                this.Data.shippingPercentage = res.order.shippingPercentage;
                this.Data.remainingShippingAmount = res.order.remainingShippingAmount;
                this.Data.isFreeShipping = res.order.isFreeShipping;
                this.Data.cartList = res.order.products;
                this.Data.freeShippingAvailable = res.order.freeShippingAvailable;
                this.Data.discountCouponCode = res.order.discountCouponCode;
                this.Data.minimumPieces = res.order.minimumPieces;
            }
            this.Data.order = res.order;
        });
    }

    public getBrands() {
        return [
            { name: 'aloha', image: 'assets/images/brands/aloha.png' },
            { name: 'dream', image: 'assets/images/brands/dream.png' },
            { name: 'congrats', image: 'assets/images/brands/congrats.png' },
            { name: 'best', image: 'assets/images/brands/best.png' },
            { name: 'original', image: 'assets/images/brands/original.png' },
            { name: 'retro', image: 'assets/images/brands/retro.png' },
            { name: 'king', image: 'assets/images/brands/king.png' },
            { name: 'love', image: 'assets/images/brands/love.png' },
            { name: 'the', image: 'assets/images/brands/the.png' },
            { name: 'easter', image: 'assets/images/brands/easter.png' },
            { name: 'with', image: 'assets/images/brands/with.png' },
            { name: 'special', image: 'assets/images/brands/special.png' },
            { name: 'bravo', image: 'assets/images/brands/bravo.png' }
        ];
    }

    public getCountries() {
        return [
            environment.country === 'MX' ? { name: 'México', code: 'MX' } : { name: 'USA', code: 'USA' }
        ]
    }

    public getMonths() {
        return [
            { value: '01', name: 'January' },
            { value: '02', name: 'February' },
            { value: '03', name: 'March' },
            { value: '04', name: 'April' },
            { value: '05', name: 'May' },
            { value: '06', name: 'June' },
            { value: '07', name: 'July' },
            { value: '08', name: 'August' },
            { value: '09', name: 'September' },
            { value: '10', name: 'October' },
            { value: '11', name: 'November' },
            { value: '12', name: 'December' }
        ]
    }

    public getYears() {
        return ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
    }

    async checkLoggedUser(): Promise<boolean> {
        try {
            await this.checkUserStatus()
            const userLoggedDataBD = localStorage.getItem("userLoggedDataBD" + environment.ambiente);
            if (!userLoggedDataBD || userLoggedDataBD === '' || userLoggedDataBD === 'undefined') {
                this.getUserDataBD(() => {
                    this.getBytoken(() => { })
                });
            } else {
                this.Data.userLoggedDataBD = JSON.parse(userLoggedDataBD);
                this.Data.userName = this.Data.userLoggedDataBD.name;
            }
            return true
        }
        catch (error) {
            await this.checkUserStatus()
            console.error('Authentication error 1***:', error);
            this.spinner.hide();
            this.translate.get('PAGES.LOGIN').subscribe((translation: string) => {
                this.Data.userName = translation; 
            });
            return false
        }
    }

    public async checkUserStatus() {
        
        const { tokens } = await fetchAuthSession({ forceRefresh: true });
        const accessToken = tokens?.accessToken;
        if(accessToken !== undefined){
            console.error('Log 1:Si hay token');
            
            localStorage.setItem('token' + environment.ambiente, accessToken.toString());
            if (localStorage.getItem('adminToken' + environment.ambiente) !== null){
                localStorage.setItem( 'adminToken' + environment.ambiente, accessToken.toString());
            }
            const user = await getCurrentUser();
            console.error('Log 2:Si hay usuario');
            if (!accessToken || !user) {
                console.error('Error 2:Failed to retrieve authenticated user.');
                throw new Error('Failed to retrieve authenticated user.');
            }
        }else{
            console.error('Error 1: Ya no hay sesión, accessToken indefinido., se pide token guest');
            if(localStorage.getItem('userLoggedDataBD' + environment.ambiente)!== null){
                localStorage.removeItem("userLoggedDataBD"+ environment.ambiente);
            }
            this.translate.get('PAGES.LOGIN').subscribe((translation: string) => {
                this.Data.userName = translation; 
            });
            this.getToken();
           
        }
        

        
    }

    signOut() {
        
        localStorage.removeItem("userLoggedDataBD" + environment.ambiente);
        localStorage.removeItem("userMail" + environment.ambiente);

        this.translate.get('PAGES.LOGIN').subscribe((translation: string) => {
            this.Data.userName = translation; 
        });

        this.cognitoService.signOut().
            then(() => {
                console.error('Log 3: Se fue a signout con cognito.');
                this.router.navigate(['/sign-in']);
                this.getOrder();
            }).catch((error: any) => {
                console.error('Error 4: Error con signout con cognito'+error);
                console.log("Error al cerra sesión: " + error);
            })

    }

    async rolUser() {
        let groups = [];
        const { accessToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
        if (accessToken) {
            const payloadGroups = accessToken.payload['cognito:groups'];
            if (Array.isArray(payloadGroups)) {
                groups = payloadGroups;
            }
            if (groups.includes('adminGroup') || groups.includes('employeeGroup')) {
                const accessGroup = groups.includes('adminGroup') ? 'admin' : 'employee';
                this.cookies.set('accessGroup', accessGroup);
                localStorage.setItem("adminToken" + environment.ambiente, accessToken.toString());
                this.router.navigate(['admin/sales/orders'])
            } else {
                this.router.navigate(['account/dashboard']);
            }
        } else {
            // console.error('Error 3: Ya no hay sesión, accessToken false.');
            // this.translate.get('PAGES.LOGIN').subscribe((translation: string) => {
            //     this.Data.userName = translation; 
            // }); 
         
            if(localStorage.getItem('userLoggedDataBD' + environment.ambiente) !== undefined && localStorage.getItem('userLoggedData' + environment.ambiente) !== null) {
                this.getToken();
            }  
            this.router.navigate(['/sign-in'])
        }
    }

    getUserDataBD(resolve) {
        this.usersService.getUserData().subscribe((res: any) => {
            if(res.mensaje === undefined || res.mensaje.indexOf('200--OK')>-1){
                localStorage.setItem("userLoggedDataBD" + environment.ambiente, JSON.stringify(res['user']));
                this.Data.userLoggedDataBD = res['user'];
                this.Data.userName = res['user']['name'];
                if (resolve) {
                    resolve();
                }
            }/* else{
                localStorage.removeItem("userLoggedDataBD");
            } */
        });
    }


    public getBytoken(resolve) {
        this.ordersService.getOrdersBytoken().subscribe((res: Ordenes) => {
            if (res.order?.products) {
                this.Data.totalPrice = res.order.amount;
                this.Data.totalCartCount = res.order.quantity;
                this.Data.shippingPercentage = res.order.shippingPercentage;
                this.Data.remainingShippingAmount = res.order.remainingShippingAmount;
                this.Data.isFreeShipping = res.order.isFreeShipping;
                this.Data.freeShippingAvailable = res.order.freeShippingAvailable;
                this.Data.cartList = res.order.products;
                this.Data.minimumPieces = res.order.minimumPieces;
            }
            this.Data.order = res.order;
            if (resolve) {
                resolve();
            }
        });

    }

    linkOrder() {
        this.ordersService.linkOrder().subscribe((res: any) => {
            console.log("Carrito actualizado");
        });
    }

    afterRRSSLoginSuccess() {
        this.linkOrder();
        this.getOrder();
    }

    public async isUserLoggedIn(): Promise<boolean> { 
        try {
            const { tokens } = await fetchAuthSession({ forceRefresh: true }); 
            const accessToken = tokens?.accessToken; 
            const user = await getCurrentUser(); 
            
            return !!(accessToken && user); 
        } catch (error) {
            return false;
        }
        
    }
    public setCountry(country: string) {
        this.Data.country = country;
    }

    public getCountryLabel(){
        var country_label = "México";
        switch (environment.country) {
          case 'MX':
            country_label = "México";
            break;
          case 'USA':
            country_label = "USA";
            break;
          default:
            country_label = "México";
            break;
        }
        return country_label;
      }
    
      public getCountryLanguaje(){
        var country_lang = "es";
        switch (this.Data.country) {
          case 'MX':
            country_lang = "es";
            break;
          case 'USA':
            country_lang = "en";
            break;
          default:
            country_lang = "es";
            break;
        }
        return country_lang;
      }

      getToken() {
        this.usersService.guestLogin().subscribe((res: GuestLogin) => {
          const response: GuestLogin = {
            token: res.token
          };
          localStorage.setItem('token' + environment.ambiente, response.token);
          this.getOrder();
        });
      }

      async getStates() {
        try {
          return(await firstValueFrom(this.catalogsService.getCatalogsByLabel('States')))['catalog'][0].values;
        } catch (error) {
          console.error("Error obteniendo estados:", error);
        }
      }
      
      


    // onPhoneInput(phoneValue): string {
    //     phoneValue = phoneValue.replace(/[^\d+]/g, '');

    //     if (!phoneValue.startsWith('+1')) {
    //         phoneValue = phoneValue.replace(/^\+/, '');
    //         phoneValue = '+1' + phoneValue;
    //     }

    //     return phoneValue;
        
    // }
} 