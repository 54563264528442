<footer class="bg-primary footer">
    <div fxLayout="row" class="py-3 border-bottom-mute theme-container" fxLayoutAlign="center center" fxLayout.xs="column">        
        <div fxFlex="100" fxFlex.gt-sm="auto" class="text-center"> 
            <h3 class="col-title">{{ 'SITE_MAP.TITLE' | translate }}</h3>    
            <p class="mt-1"><a target="_blank" href="https://api.whatsapp.com/send?phone=+52{{ 'CONTACT.COMPACT_PHONE' | translate }}&text=¡Hola! Estoy interesado en sus productos, necesito ayuda." class="link secondary-color">{{ 'FOOTER.CONTACT' | translate }}</a></p>
            <p class="mt-1"><a routerLink="/products" class="link secondary-color">{{ 'FOOTER.PRODUCTS' | translate }}</a></p>
            <p class="mt-1"><a routerLink="/cart" class="link secondary-color">{{ 'FOOTER.CART' | translate }}</a></p>
            <p class="mt-1"><a href="/faqs" class="link secondary-color">{{ 'FOOTER.FAQS' | translate }}</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="auto" ngClass.xs="mt-2" class="contact-information text-center"> 
            <h3 class="col-title">{{ 'CONTACT.TITLE' | translate }}</h3>            
            <p fxLayout="row" fxLayoutAlign="center center" class="mt-1 secondary-color">
                <mat-icon>call</mat-icon>
                <span><a href="tel:+52{{ 'CONTACT.COMPACT_PHONE' | translate }}" class="phone_link">{{ 'CONTACT.PHONE' | translate }}</a></span>
            </p>
            <a href="mailto:{{ 'CONTACT.EMAIL' | translate }}">
                <p fxLayout="row" fxLayoutAlign="center center" class="mt-1 secondary-color">
                    <mat-icon>mail_outline</mat-icon>
                    <span>{{ 'CONTACT.EMAIL' | translate }}</span>
                </p>
            </a>
            <br>
            <p class="mt-1"><a routerLink="/privacy-policy" class="link secondary-color">{{ 'FOOTER.PRIVACY_POLICY' | translate }}</a></p>
        </div>
    </div>
</footer>