<div class="p-1">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{(id) ? 'Editar' : 'Agregar nuevo'}} producto</mat-card-title>
            <!-- <button mat-button (click)="openDialog()">
                Agregar variante
            </button>  -->
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off" fxLayout="row wrap">
                <div fxFlex="30" class="px-2"> 
                    <p class="mt-1"><span class="uppercase fw-500">Galería</span></p>  
                    <input-file formControlName="images" fileLimit="1" fileAccept="image/*" ></input-file>  
                    <button *ngIf="id" mat-raised-button color="primary" type="button" (click)="updateImage()" >Guardar imagen</button>
                </div>
                <div fxFlex="70" class="px-2">
                    <div fxLayout="row wrap">
                        <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Nombre</mat-label>
                                <input matInput formControlName="name" required>
                                <mat-error *ngIf="form.controls.name.errors?.required">El nombre es requerido</mat-error>
                                <mat-error *ngIf="form.controls.name.hasError('minlength')">Mínimo 4 caracteres</mat-error>
                            </mat-form-field>
                        </div> 
                        <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ 'PRODUCT_DETAIL.CATEGORY' | translate }}</mat-label>
                                <mat-select [ngModel]="categorySelected" formControlName="category" required >
                                    <mat-option *ngFor="let cat of categories" [value]="cat.label">
                                        <span>{{cat.label}}</span>
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.controls.category.errors?.required">La categoría es requerida</mat-error>
                            </mat-form-field>
                        </div> 
                        <div fxFlex="100" class="px-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ 'PRODUCT_DETAIL.DESCRIPTION' | translate }}</mat-label> 
                                <textarea matInput formControlName="description" rows="7"></textarea> 
                            </mat-form-field> 
                        </div> 
                        
                        <div fxFlex="100" fxFlex.gt-sm="{{variantsAdded.length==0?'50':'100'}}" class="px-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Variantes</mat-label>
                                <mat-select formControlName="variant" multiple (selectionChange)="onVariantsChange($event)">
                                    <!-- <mat-select-trigger>{{ variant.selected }}</mat-select-trigger> -->
                                    <mat-option *ngFor="let value of variantsCatalog" [value]="value._id">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <span>{{value.label}}</span>
                                            <mat-icon [style.background-color]="value.label"></mat-icon>
                                        </div> 
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="100" fxFlex.gt-sm="50" class="px-1"> 
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Etiquetas</mat-label>
                                <mat-select formControlName="tags" multiple (selectionChange)="onTagsChange($event)">
                                    <mat-option *ngFor="let value of tags" [value]="value.value">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <span>{{value.label}}</span>
                                            <mat-icon [style.background-color]="value.label"></mat-icon>
                                        </div> 
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ 'PRODUCT_DETAIL.CURRENCY' | translate }}</mat-label>
                                <mat-select [ngModel]="currencySelected" formControlName="currency" >
                                    <mat-option *ngFor="let currency_v of currency" [value]="currency_v">
                                        <span>{{currency_v}}</span>
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.controls.category.errors?.required">La categoría es requerida</mat-error>
                            </mat-form-field>
                        </div> 
                       
                        <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" *ngIf="variantsAdded.length==0">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ 'PRODUCT_DETAIL.SKU' | translate }}</mat-label>
                                <input matInput formControlName="sku" type="text"> 
                            </mat-form-field>
                        </div>

                            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" *ngIf="variantsAdded.length==0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{ 'PRODUCT_DETAIL.PRICE' | translate }}</mat-label>
                                    <input matInput formControlName="price" type="number">
                                    <!-- <mat-error *ngIf="form.controls.price.errors?.required">El precio es requerido</mat-error> -->
                                </mat-form-field>
                            </div> 
                            
                             
                            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" *ngIf="variantsAdded.length==0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{ 'PRODUCT_DETAIL.AVAILABILITY' | translate }}</mat-label>
                                    <input matInput formControlName="stock" type="number"> 
                                </mat-form-field>
                            </div> 
                            <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{ 'PRODUCT_DETAIL.VENDORS' | translate }}</mat-label>
                                    <mat-select formControlName="vendorId" (selectionChange)="onVendorSelectionChange($event)" >
                                        <mat-option *ngFor="let vendor of vendors" [value]="vendor.value" >
                                            <span>{{vendor.label}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> 
                            
                        <div *ngFor="let variant of variants" fxFlex="100" fxFlex.gt-sm="50" class="px-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ variant.label}}</mat-label>
                                <mat-select formControlName="{{ variant._id}}-Variant" multiple  (selectionChange)="onVariantSelectionChange($event.value,variant._id)">
                                    <!-- <mat-select-trigger>{{ variant.selected }}</mat-select-trigger> -->
                                    <mat-option *ngFor="let value of variant.values" [value]="value._id">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <span>{{value.label}}</span>
                                            <mat-icon [style.background-color]="value.label"></mat-icon>
                                        </div> 
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> 
                        
                        <div fxFlex="100" fxFlex.gt-sm="33" class="px-1">
                            <mat-slide-toggle formControlName="enabled">Habilitado</mat-slide-toggle>
                        </div>
                        <div fxFlex="100" fxFlex.gt-sm="33" class="px-1">
                            <mat-slide-toggle formControlName="couponEnabled">¿Se le puede aplicar cupón?</mat-slide-toggle>
                        </div>
                        <div fxFlex="100" fxFlex.gt-sm="33" class="px-1">
                            <mat-slide-toggle formControlName="freeShippingAvailable">¿Puede aplicar envío gratis?</mat-slide-toggle>
                        </div>

                        <div fxFlex="100" fxFlex.gt-sm="100" class="px-1 top-20">
                            <p ><span class="fw-500">{{ 'PRODUCT_DETAIL.MEASUREMENTS' | translate }}</span></p>  
                        </div>
                        <div fxFlex="100" fxFlex.gt-sm="100" class="row">
                            <div fxFlex="25" fxFlex.gt-sm="25" class="px-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{ 'PRODUCT_DETAIL.HEIGHT' | translate }}</mat-label>
                                    <input matInput formControlName="height" type="number" required> 
                                </mat-form-field>
                            </div>
                            <div fxFlex="25" fxFlex.gt-sm="25" class="px-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{ 'PRODUCT_DETAIL.LENGTH' | translate }}</mat-label>
                                    <input matInput formControlName="length" type="number" required> 
                                </mat-form-field>
                            </div>
                            <div fxFlex="25" fxFlex.gt-sm="25" class="px-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{ 'PRODUCT_DETAIL.DEPTH' | translate }}</mat-label>
                                    <input matInput formControlName="width" type="number" required> 
                                </mat-form-field>
                            </div>
                            <div fxFlex="25" fxFlex.gt-sm="25" class="px-1">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{ 'PRODUCT_DETAIL.WEIGHT' | translate }}</mat-label>
                                    <input matInput formControlName="weight" type="number" required> 
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxFlex="100" class="mt-2 text-center w-100">
                            <button mat-raised-button color="primary" type="submit" >{{(variantsAdded.length==0) ? 'GUARDAR' : 'SIGUIENTE'}}</button>
                        </div>
                    </div>
                    
                </div>
            </form>
            
            <form [formGroup]="formTable" (ngSubmit)="onSubmitTable()" autocomplete="off" class="right" *ngIf="variantsAdded.length>0">
                <div fxFlex="100" class="px-2">
                    <div fxLayout="row wrap" *ngIf="variantsCatalogTable.length>0" style="margin:auto" >
                        <mat-card-title class="p-1 variant_title">Variantes</mat-card-title>
                        <div class="mat-table admin-table" style="text-align:right">
                            <div *ngFor="let cat of variantsSaved; let first = first; let last = last" class="mat-row" [ngClass]="{ first: first, last: last }">
                                <div *ngFor="let value of variantsCatalogTable">
                                    <div class="mat-cell">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{ value.label}}</mat-label>
                                            <mat-select formControlName="{{value._id}}-TableVariant-{{cat}}">
                                                <!-- <mat-select-trigger>{{ variant.selected }}</mat-select-trigger> -->
                                                <mat-option *ngFor="let variant of value.values" [value]="variant._id" required>
                                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                                        <span>{{variant.label}}</span>
                                                        <mat-icon [style.background-color]="variant.label"></mat-icon>
                                                    </div> 
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="mat-cell" style="min-width: 100px;">
                                    <mat-form-field appearance="outline" class="w-100" >
                                        <mat-label>{{ 'PRODUCT_DETAIL.PRICE' | translate }}</mat-label>
                                        <input matInput formControlName="price-{{cat}}" required>
                                        <mat-error *ngIf="formTable.controls.price-{cat}.errors?.required">El precio es requerido</mat-error>
                                    </mat-form-field>
                                </div>  
                                <!-- <div class="mat-cell">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>{{ 'PRODUCT_DETAIL.CURRENCY' | translate }}</mat-label>
                                        <mat-select formControlName="currency-{{cat}}">
                                            <mat-option *ngFor="let value of currency" [value]="value">
                                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                                    <span>{{value}}</span>
                                                </div> 
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>   -->
                                <div class="mat-cell" style="min-width: 100px;">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>{{ 'PRODUCT_DETAIL.AVAILABILITY' | translate }}</mat-label>
                                        <input matInput formControlName="stock-{{cat}}" type="number"> 
                                    </mat-form-field>
                                </div>  
                                <div class="mat-cell" style="min-width: 100px;">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>{{ 'PRODUCT_DETAIL.SKU' | translate }}</mat-label>
                                        <input matInput formControlName="sku-{{cat}}" type="text" required> 
                                    </mat-form-field>
                                </div>  
                                <div class="mat-form-field-wrapper actionsButtons" style="min-width: 130px;">
                                    <div class="p-1 actions" layout="row" layout-align="center center" >  
                                        <button mat-mini-fab color="primary" matTooltip="Agregar" (click)="addVariant(cat)" *ngIf="last" type="button"  >
                                            <mat-icon>add</mat-icon>
                                        </button>  
                                        <!-- <button mat-mini-fab color="primary" matTooltip="Guardar" (click)="saveVariant(cat)" >
                                            <mat-icon>save</mat-icon>
                                        </button> -->   
                                        <button mat-mini-fab color="warn" matTooltip="Borrar" class="mx-1" type="button" (click)="removeVariant(cat)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        
                                    </div>
                                </div>       
                            </div>
                        </div>
                        <div fxFlex="100" class="mt-2 text-center d-flex justify-content-evenly w-100 variantsButtons">
                            <button mat-raised-button color="primary" type="submit">GUARDAR</button>
                        </div>
                    </div>
                </div>
                    
                    
            </form>
        </mat-card-content>
       
       
        
    </mat-card>
</div>
